// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)


// reset
html {
  max-width: 130ch;
  padding: 0 1em;
  margin: auto;
  line-height: 1.75;
  font-size: 1.25em;
}

main {
  max-width: 90rem;
  padding: 2rem;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1em 0;
}

// hamburger menu
#menu__toggle {
  opacity: 0;
}

#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}

#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}

#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}

#menu__toggle:checked ~ .menu__box {
  left: 0 !important;
}

.menu__btn {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #616161;
  transition-duration: .25s;
}

.menu__btn > span {
  font-size: 1px;
  top: 12px;
}

.menu__btn > span::before {
  content: '';
  top: -8px;
}

.menu__btn > span::after {
  content: '';
  top: 8px;
}

.menu__box {
  display: block;
  position: fixed;
  top: 0;
  left: -20rem;
  width: 20rem;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: lighten($dm-background-color, 5%);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
  transition-duration: .25s;
}

.menu__item {
  display: block;
  padding: 12px 24px;
  color: $dm-link-base-color;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: .25s;
}

.menu__item:hover {
  background-color: lighten($dm-background-color, 15%);
}

// header

header {
  h1, h2 {
    margin: 0 2rem;
  }
}

.site-header {
  min-height: $spacing-unit * 1.9;
}

.site-title {
  width: 100%;
}

nav {
  height: 0;
}

.footer-col-wrapper {
  line-height: 1.2em;
}

// main
.page-content {
  padding: 0;
}

a.post-link h2 {
  margin-top: 0.1rem;
}

ul.post-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45ch, 1fr));


  li {
    padding: 0 2rem;
    margin-bottom: 2.5rem;
    min-height: 10rem;
    max-height: 15rem;

    a {
      width: 100%;
      height: 100%;
      font-size: 1rem;
    }
  }

  li:hover {
    background-color: lighten($dm-background-color, 5%);
  }

}

// post

article {
  font-size: 1.1rem;
  text-align: justify;

  header {
    h1, h2 {
      margin: 0.25rem 0;
    }
  }

  p.listing {
    clear: both;
    margin-bottom: 0;
    margin-text-outline: .5em;
    background: lighten($code-background-color, 5%);
    width: 40rem;
    padding: .1em .5em;
    border-radius: 3px 3px 0 0;
    border: 1px solid $border-color-01;
    border-bottom: none;
  }
}

.highlight {
  border-radius: 0 3px 3px 3px;

}

.video {
  aspect-ratio: 16/9;
  margin: 0 auto calc($spacing-unit / 2);

  iframe {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: $on-medium) {
  .video {
    width: 30rem;
  }
}

@media screen and (max-width: ($on-medium - 1)) {
  .video {
    width: 90%;
  }
}

// article list

ul.part-list {
  list-style-type: none;
}


// pager

ul.pager {
  list-style: none;
  margin: 0 auto 1rem;
  font-size: 1.2rem;
  display: block;
  max-width: 25rem;
  text-align: center;

  li {
    display: inline-block;
    min-width: 2.5rem;
    min-height: 2.5rem;
    border: 1px solid $border-color-01;

    a {
      vertical-align: middle;
      padding-top: 0.25rem;
    }
  }

  li.selected {
    background-color: lighten($dm-background-color, 5%);
  }
}

a.gh-link-img img {
  max-height: 12rem;
  min-height: 12rem;
  min-width: 24rem;
  min-width: 24rem;
  display: block;
}

.page-navigation {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-bottom: calc($spacing-unit / 2);

  .page-navigation-next {
    text-align: right;
  }
}

// offtopic

.offtopic {

  h2 {
    font-size: 1.25rem;
    font-weight: bolder;
    margin: 0;
  }


  &.f-right {
    float: right;
    border-left: 0.1rem solid $border-color-01;
    margin-left: 2rem;
  }

  &.f-left {
    float: left;
    border-right: 0.1rem solid $border-color-01;
    margin-right: 2rem;
  }

  padding: 0.75rem 1.25rem;
  background-color: lighten($dm-background-color, 5%);
  font-style: italic;

  &:before {
    content: "Offtopic";
    width: 100%;
    display: block;
    font-variant-caps: small-caps;
    font-variant-ligatures: common-ligatures;
    padding-bottom: 0.25rem;
  }
}

@media screen and (min-width: $on-medium) {
  .offtopic {
    width: 30%;

    &.f-right {
      float: right;
      border-left: 0.1rem solid $border-color-01;
      margin-left: 2rem;
    }

    &.f-left {
      float: left;
      border-right: 0.1rem solid $border-color-01;
      margin-right: 2rem;
    }

  }
}

@media screen and (max-width: ($on-medium - 1)) {
  .offtopic {

    &.f-right {
      float: none;
      border-left: 0.1rem solid $border-color-01;
      margin-left: 0;
    }

    &.f-left {
      float: none;
      border-right: 0.1rem solid $border-color-01;
      margin-right: 0;
    }

  }
}

.tabs {
  display: flex;
  position: relative;

  label {
    display: block;
    min-width: 10rem;
    border: 1px solid $border-color-01;
    background-color: lighten($dm-background-color, 5%);
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] ~ div {
    display: none;
    position: absolute;
    left: 0;
  }

  input[type="radio"]:checked ~ label {
    background-color: lighten($dm-background-color, 10%);
  }

  input[type="radio"]:checked ~ div {
    display: block;
  }
}