// Placeholder to allow overriding predefined variables smoothly.

$content-width: 50rem;
$spacing-unit: 2rem;


$dm-link-base-color:    #e2f1f1;
$link-base-color:       #e2f1f1;
$link-visited-color:    $link-base-color;
$link-hover-color:      $text-color;
